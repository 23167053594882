import { useEffect } from "react"

const Biblioteka = () => {
  useEffect(() => {
    window.location.replace("https://www.libib.com/u/moja-oaza")
  })

  return null
}

export default Biblioteka
